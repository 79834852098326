<template>
  <div class="page-container">
    <!--    面包屑导航区-->
<!--    <el-breadcrumb separator-class="el-icon-arrow-right">-->
<!--      <el-breadcrumb-item :to="{ path: '/welcome' }">主页</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>患者管理</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>患者数据</el-breadcrumb-item>-->
<!--    </el-breadcrumb>-->
    <Breadcrumb></Breadcrumb>
    <el-card>
      <div class="databoard">
        <div class="databoard_form">
          <div class="databoard_form_list">
            <div class="databoard_form_title">
              <i class="el-icon-s-opportunity"></i>
              <div class="_margin _margin">{{activeDate}}新增患者:</div>
              <div class="databoard_form_num _margin">{{ patientsCount }}</div>
            </div>
            <div class="databoard_form_list_warpper">
              <el-table :data="todayPatientsList" border  size="mini">
                <el-table-column type="index" label="序号" align="center"></el-table-column>
                <el-table-column prop="name" label="患者姓名"  align="center"></el-table-column>
                <el-table-column prop="phone" label="手机号码"  align="center"></el-table-column>
                <el-table-column prop="createTime" label="注册时间"  align="center">
                  <template
                    slot-scope="socpe"
                  >{{ socpe.row.createTime | dateFormat('YYYY-MM-DD HH:mm') }}</template>
                </el-table-column>

                <el-table-column prop="city" label="注册地址"  align="center">
                  <template slot-scope="socpe">
                    <span>{{ socpe.row.province }}</span>
                    <span>{{ socpe.row.city }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="selectInfo.pagenum"
                :page-size="selectInfo.pagesize"
                layout="slot, prev, pager, next, jumper"
                :total="total"
                style="margin-left: 30px"
              >
                <span class="total">共 {{ total }} 条数据，共 {{ Math.ceil(total / selectInfo.pagesize) }} 页</span>
              </el-pagination>
            </div>
          </div>
          <div class="databoard_chart">
            <div class="selectOptions">
              <div class="btngroup">
                <el-button size="mini" @click="getTodayNewPatientsList('今日')">今日</el-button>
                <el-button size="mini" @click="getWeeksPatientList('近七天')">近七天</el-button>
                <el-button size="mini" @click="getMonthsPatientList('近一月')">近一月</el-button>
                <el-button size="mini" @click="getcurrentYearNewPatients('近一年')">近一年</el-button>
              </div>
<!--              <div class="allcount">-->
<!--                <span>累计新增:</span>-->
<!--                <span>{{ patientsCount }}</span>-->
<!--              </div>-->
            </div>
            <div id="myChart" :style="{ width: '800px', height: '600px' }"></div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "newpatientdata",
  data() {
    return {
      todayNewPatiens: '',
      patientsCount: '',
      //筛选日期值
      selectDate: '',
      selectInfo: {
        pagenum: 1,
        //当前每页展示的数据条数
        pagesize: 15
      },
      activeDate: '今日',
      total: 0,
      //已分配患者列表
      todayPatientsList: [],
      chartData: {
        title: { text: '新增患者统计' },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#E9EEF3'
            }
          }
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            smooth:true,//设置折线图平滑
            animation: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                fontSize: 10
              }
            },
            itemStyle: {
              normal: {
                color: '#8470FF',
                lineStyle: {        // 系列级个性化折线样式
                  width: 2,
                  type: 'solid',
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#8470FF'
                  }, {
                    offset: 1,
                    color: '#008B45'
                  }]),//线条渐变色
                }
              },
              emphasis: {
                color: '#008B45',
                lineStyle: {        // 系列级个性化折线样式
                  width: 3,
                  type: 'solid',
                  color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#00FFFF'
                  }, {
                    offset: 1,
                    color: '#5F9EA0'
                  }])
                }
              }
            },
          }
        ],
        animationEasing: 'cubicIn',
        animationEasingUpdate:'cubicOut'
      }
    }
  },
  mounted() {

  },
  created() {
    this.getTodayNewPatientsList()
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.selectInfo.pagesize = val
      this.toPages()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.selectInfo.pagenum = val
      this.toPages()

    },
    //绘制Ecahrts图标
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'),'dark')
      myChart.setOption(this.chartData)
    },

    //获取今日新增数据
    async getTodayNewPatientsList() {
      this.activeDate = '今日'
      this.selectInfo.pagesize = 15
      this.selectInfo.pagenum = 1
      console.log(this.selectInfo,'========今天数据的参数=========')
      const res = await this.$http.getTodayNewPatients(this.selectInfo)
      console.log(res)
      if (res.result.code !== 1) return this.$message.error('数据获取失败!')
      this.todayPatientsList = res.result.data.data.data
      this.total = res.result.data.data.records
      this.patientsCount = res.result.data.data.records
      this.todayNewPatiens = res.result.data.data.records
      this.initEcharts(10, 16, res)

    },
    //获取本周数据
    async getWeeksPatientList() {
      this.activeDate = '近七天'
      this.selectInfo.pagesize = 15,
        this.selectInfo.pagenum = 1

      const res = await this.$http.getDay7NewPatients(this.selectInfo)
      if (res.result.code !== 1) return this.$message.error('数据获取失败!')
      console.log(res)
      this.todayPatientsList = res.result.data.data.data
      this.total = res.result.data.data.records
      this.patientsCount = res.result.data.data.records
      this.initEcharts(5, 10, res)
    },
    //获取本月数据
    async getMonthsPatientList() {
      this.activeDate = '近一月'
      this.selectInfo.pagesize = 15,
        this.selectInfo.pagenum = 1

      const res = await this.$http.getcurrentMontsNewPatients(this.selectInfo)
      if (res.result.code !== 1) return this.$message.error('数据获取失败!')
      console.log(res)
      this.todayPatientsList = res.result.data.data.data
      this.total = res.result.data.data.records
      this.patientsCount = res.result.data.data.records
      this.initEcharts(0, 10, res)
    },
    //获取本年数据
    async getcurrentYearNewPatients() {
      this.activeDate = '近一年'
      this.selectInfo.pagesize = 15,
        this.selectInfo.pagenum = 1
      const res = await this.$http.getcurrentYearNewPatients(this.selectInfo)
      if (res.result.code !== 1) return this.$message.error('数据获取失败!')
      this.patientsCount = res.result.data.data.records
      this.todayPatientsList = res.result.data.data.data
      this.total = res.result.data.data.records
      this.initEcharts(0, 10, res)
    },
    //初始化图标
    initEcharts(startStr, endStr, Value) {
      let xais = []
      let yais = []
      var seriesYdata = Object.keys(Value.result.data.num).sort()
      //处理纵坐标
      seriesYdata.forEach((item) => {
        xais.push(Value.result.data.num[item])
      })
      //处理横坐标
      seriesYdata.forEach((items) => {
        let _items = items.slice(startStr, endStr)
        yais.push(_items)
      })
      console.log(xais)
      console.log(yais)
      //初始化图标
      this.chartData.xAxis.data = yais
      this.chartData.series[0].data = xais
      this.drawLine()
    },
    //切换页码
    async toPages() {
      console.log('切换分页')
      let _params = {
        page: this.selectInfo.pagenum,
        pageSize: this.selectInfo.pagesize
      }
      console.log(_params)
      if (this.activeDate == '今日') {
        const res = await this.$http.getTodayNewPatients(_params)
        this.toData(res)
      } else if (this.activeDate == '近七天') {
        const res = await this.$http.getDay7NewPatients(_params)
        console.log(res, 'week=================')
        this.toData(res)
      } else if (this.activeDate == '近一月') {
        const res = await this.$http.getcurrentMontsNewPatients(_params)
        this.toData(res)
      } else if (this.activeDate == '近一年') {
        const res = await this.$http.getcurrentYearNewPatients(_params)
        this.toData(res)
      }
      // if (this.activeDate=='week'){this.getWeeksPatientList()}
      // if (this.activeDate=='month'){this.getMonthsPatientList()}
      // if (this.activeDate=='year'){this.getcurrentYearNewPatients()}
    },
    //数据赋值
    toData(res) {
      if (res.result.code !== 1) return this.$message.error('数据获取失败!')
      this.todayPatientsList = res.result.data.data.data
      this.total = res.result.data.data.records

    }
  }
}
</script>

<style scoped>
.databoard {
  width: 100%;
  height: 720px;
  display: flex;
  justify-content: space-between;
}
.databoard_form_title {
  display: flex;
  align-items: center;
}
.databoard_form {
  height: 500px;
  display: flex;
  justify-content: space-between;
}
.databoard_chart {
  margin-left: 150px;
}
.el-icon-s-opportunity {
  font-size: 24px;
  color: #1296db;
}
._margin {
  margin-left: 10px;
}
.databoard_form_num {
  font-size: 24px;
  color: #1296db;
}

.el-card {
  height: 100%;
}
.el-table {
  max-height: 590px;
  overflow-y: scroll;
  width: 698px;
}
.databoard_form_list_warpper {
  overflow: hidden;
}
/*取消滚动条*/
.el-table::-webkit-scrollbar {
  display: none;
}
.selectOptions {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.el-date-picker {
  margin-right: 20px;
}
.btngroup {
  margin-left: 20px;
}
.el-pagination {
  margin-top: 20px;
}
.allcount {
  margin-left: 15px;
}
.total {
  margin-right: 24px;
  font-weight: 400 !important;
  color: #606266;
}
</style>